<template>
  <v-card class="border-radius-xl card-shadow mt-10 mx-4" v-if="asuulga">
    <h1 class="mx-4 pt-4">
      Сэдэв: <span>{{ asuulga.title }}</span>
    </h1>
    <h4 class="mx-4 text-gray">
      Сурагчийн тоо: {{ asuulga.numberOfparticipants }} /
      <span v-if="asuulga.teachers">{{ asuulga.teachers.length }}</span>
    </h4>
    <v-row class="ma-0">
      <v-btn
        @click="showAddTeacherDialog = true"
        color="red"
        dark
        small
        class="ma-4"
        >add Teachers</v-btn
      >
      <v-btn
        @click="copyTeacher()"
        dark
        small
        class="ma-4 ml-0 bg-gradient-warning"
        >copy teachers</v-btn
      >
    </v-row>

    <v-row v-if="asuulga">
      <v-col md="10" lg="12" class="mx-auto">
        <v-tabs v-model="classGroupsTab">
          <v-tab v-for="(classGroup, cIndex) in classGroups" :key="cIndex">
            {{ classGroup.id }}
            <span
              v-if="classGroup.teachers && classGroup.teachers.length > 0"
              >-({{ classGroup.teachers.length }})</span
            >
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="classGroupsTab">
          <v-tab-item
            v-for="(classGroup, cIndex) in classGroups"
            :key="cIndex"
            class="asuulgateacher"
          >
            <v-card class="px-16">
              <h1 class="mt-4 py-4">{{ classGroup.id }}</h1>
              <table v-if="classGroup.teachers">
                <tbody>
                  <tr
                    v-for="(teacher, tindex) in classGroup.teachers"
                    :key="'teachers' + teacher.id"
                  >
                    <td>{{ tindex + 1 }}</td>
                    <td>{{ teacher.id }}</td>
                    <td>{{ teacher.numberOfparticipants }}</td>
                    <td v-if="teacher.lessons">{{ teacher.lessons.length }}</td>
                    <td v-else>-</td>
                    <td
                      v-for="(lesson, lindex) in teacher.lessons"
                      :key="lindex + 'lesson'"
                    >
                      {{ lesson.lessonName }} /
                      {{ lesson.numberOfparticipants }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md="8" class="mx-auto">
        <v-data-table
          loading
          loading-text="Мэдээлэл байxгүй байна"
          class="elevation-1"
        ></v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="showAddTeacherDialog" max-width="600px">
      <v-card class="py-4">
        <v-card-title class="headline"> Бөөнөөр нэмэx </v-card-title>
        <v-card-text>
          <v-container>
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.name" label="Нэр" />
              </v-col>
            </v-row> -->

            <v-card-text>
              <v-select
                :items="[12, 11, 10, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]"
                v-model="selectedDepartment"
              >
              </v-select>
            </v-card-text>
            <v-row v-if="selectedDepartment">
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">
                  Нэр, утас, имэйл дарааллаар мэдээлэл оруулаx
                </p>
                <v-textarea
                  v-model="bulkText"
                  outlined
                  label="Багана мэдээлэл xуулж оруулаx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="px-8">
          <v-spacer />
          <v-btn
            color="darken-1"
            text
            @click="
              showAddTeacherDialog = false;
              bulkText = null;
            "
          >
            Цуцлаx
          </v-btn>

          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="_saveBulk"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
// import XLSX from "xlsx";
Vue.use(VueSweetalert2);

const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data: () => ({
    selectedDepartment: null,
    bulkText: null,
    showAddTeacherDialog: false,
    classGroupsTab: null,
    asuulga: null,
    classGroups: null,
    loading: false,
    asuulgas: null,
    rules: [],

    headers: [
      {
        text: "No",
        align: "start",
        sortable: true,
        value: "index",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: false,
        value: "firstName",
      },
      {
        text: "Xамрагдсан тоо",
        align: "start",
        sortable: true,
        value: "numberOfparticipants",
      },
    ],
    showStudentTimeDialog: false,
  }),
  props: {
    sorilId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    console.log(this.sorilId);

    fb.db.doc(this.sorilId).onSnapshot((doc) => {
      let asuulga = doc.data();
      asuulga.id = doc.id;
      asuulga.ref = doc.ref;
      this.asuulga = asuulga;
    });
    fb.db
      .doc(this.sorilId)
      .collection("teachers")
      .orderBy("departmentIndex", "asc")
      .onSnapshot((docs) => {
        this.classGroups = [];
        docs.forEach((doc) => {
          let classGroup = doc.data();
          classGroup.id = doc.id;
          classGroup.ref = doc.ref;
          classGroup.teachers = null;
          classGroup.ref.collection("teachers").onSnapshot((docs) => {
            classGroup.teachers = [];
            docs.forEach((doc) => {
              let teacher = doc.data();
              teacher.id = doc.id;
              teacher.ref = doc.ref;
              teacher.lessons = null;
              teacher.ref.collection("lessons").onSnapshot((docs) => {
                teacher.lessons = [];
                docs.forEach((doc) => {
                  let lesson = doc.data();
                  lesson.id = doc.id;
                  lesson.ref = doc.ref;
                  teacher.lessons.push(lesson);
                });
              });
              classGroup.teachers.push(teacher);
            });
          });
          this.classGroups.push(classGroup);
        });
      });
  },
  methods: {
    async copyTeacher() {
      var counter = 0;

      var list = [];
      var group1;
      var group2;
      var group3;
      var group4;
      var group5;
      var group6;
      var group7;

      var batch1 = fb.db.batch();
      var batch2 = fb.db.batch();
      var batch3 = fb.db.batch();
      var batch4 = fb.db.batch();
      var batch5 = fb.db.batch();
      var batch6 = fb.db.batch();
      var batch7 = fb.db.batch();

      var selectedAsuulga;
      await this.userData.school.ref
        .collection("asuulga")
        .doc("lgmzlfpmywx1Hbdy3Ord")
        .get()
        .then(async (doc) => {
          let asuulga = doc.data();
          // asuulga.ref = doc.ref;
          asuulga.id = doc.id;
          asuulga.availableClassGroups = null;
          await doc.ref
            .collection("teachers")
            .get()
            .then((docs) => {
              asuulga.availableClassGroups = [];
              docs.forEach(async (doc) => {
                let classGroup = doc.data();
                classGroup.id = doc.id;
                // classGroup.ref = doc.ref;
                classGroup.teachers = null;
                await doc.ref
                  .collection("teachers")
                  .get()
                  .then((docs) => {
                    classGroup.teachers = [];
                    docs.forEach(async (doc) => {
                      let teacher = doc.data();
                      teacher.id = doc.id;
                      teacher.lessons = null;
                      await doc.ref
                        .collection("lessons")
                        .get()
                        .then((docs) => {
                          teacher.lessons = [];
                          docs.forEach((doc) => {
                            let lesson = doc.data();
                            lesson.id = doc.id;
                            teacher.lessons.push(lesson);
                          });
                        });
                      classGroup.teachers.push(teacher);
                    });
                  });
                asuulga.availableClassGroups.push(classGroup);
              });
              console.log(asuulga.availableClassGroups);
            });
          selectedAsuulga = asuulga;
        });

      if (selectedAsuulga) {
        selectedAsuulga.availableClassGroups.forEach((classGroup) => {
          list.push({
            departmentIndex: classGroup.departmentIndex,
            ref: this.asuulga.ref.collection("teachers").doc(classGroup.id),
          });
          // batch.set(
          //   this.asuulga.ref.collection("teachers").doc(classGroup.id),
          //   { departmentIndex: classGroup.departmentIndex }
          // );

          classGroup.teachers.forEach((teacher) => {
            list.push({
              createdAt: new Date(),
              numberOfparticipants: teacher.numberOfparticipants,
              ref: this.asuulga.ref
                .collection("teachers")
                .doc(classGroup.id)
                .collection("teachers")
                .doc(teacher.id),
            });
            // batch.set(
            //   this.asuulga.ref
            //     .collection("teachers")
            //     .doc(classGroup.id)
            //     .collection("teachers")
            //     .doc(teacher.id),
            //   {
            //     createdAt: new Date(),
            //     numberOfparticipants: teacher.numberOfparticipants,
            //   }
            // );

            teacher.lessons.forEach((lesson) => {
              list.push({
                createdAt: new Date(),
                lessonName: lesson.lessonName,
                numberOfparticipants: lesson.numberOfparticipants,
                ref: this.asuulga.ref
                  .collection("teachers")
                  .doc(classGroup.id)
                  .collection("teachers")
                  .doc(teacher.id)
                  .collection("lessons")
                  .doc(lesson.id),
              });
              // batch.set(
              //   this.asuulga.ref
              //     .collection("teachers")
              //     .doc(classGroup.id)
              //     .collection("teachers")
              //     .doc(teacher.id)
              //     .collection("lessons")
              //     .doc(lesson.id),
              //   {
              //     createdAt: new Date(),
              //     lessonName: lesson.lessonName,
              //     numberOfparticipants: lesson.numberOfparticipants,
              //   }
              // );
              console.log(++counter);
            });
          });
        });

        console.log(list.length);
        if (list.length < 500) {
          group1 = list;
        } else if (list.length < 1000) {
          group1 = list.slice(0, 500);
          group2 = list.slice(500);
        } else if (list.length < 1500) {
          group1 = list.slice(0, 500);
          group2 = list.slice(500, 1000);
          group3 = list.slice(1000);
        } else if (list.length < 2000) {
          group1 = list.slice(0, 500);
          group2 = list.slice(500, 1000);
          group3 = list.slice(1000, 1500);
          group4 = list.slice(1500);
        } else if (list.length < 2500) {
          group1 = list.slice(0, 500);
          group2 = list.slice(500, 1000);
          group3 = list.slice(1000, 1500);
          group4 = list.slice(1500, 2000);
          group5 = list.slice(2000);
        } else if (list.length < 3000) {
          group1 = list.slice(0, 500);
          group2 = list.slice(500, 1000);
          group3 = list.slice(1000, 1500);
          group4 = list.slice(1500, 2000);
          group5 = list.slice(2000, 2500);
          group6 = list.slice(2500);
        } else if (list.length < 3500) {
          group1 = list.slice(0, 500);
          group2 = list.slice(500, 1000);
          group3 = list.slice(1000, 1500);
          group4 = list.slice(1500, 2000);
          group5 = list.slice(2000, 2500);
          group6 = list.slice(2500, 3000);
          group7 = list.slice(3000);
        } else {
          alert("Out of Bounds");
        }

        if (list.length < 500) {
          group1.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch1.set(ref, obj);
          });
        } else if (list.length < 1000) {
          group1.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch1.set(ref, obj);
          });
          group2.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch2.set(ref, obj);
          });
        } else if (list.length < 1500) {
          group1.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch1.set(ref, obj);
          });
          group2.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch2.set(ref, obj);
          });
          group3.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch3.set(ref, obj);
          });
        } else if (list.length < 2000) {
          group1.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch1.set(ref, obj);
          });
          group2.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch2.set(ref, obj);
          });
          group3.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch3.set(ref, obj);
          });
          group4.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch4.set(ref, obj);
          });
        } else if (list.length < 2500) {
          group1.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch1.set(ref, obj);
          });
          group2.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch2.set(ref, obj);
          });
          group3.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch3.set(ref, obj);
          });
          group4.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch4.set(ref, obj);
          });
          group5.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch5.set(ref, obj);
          });
        } else if (list.length < 3000) {
          group1.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch1.set(ref, obj);
          });
          group2.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch2.set(ref, obj);
          });
          group3.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch3.set(ref, obj);
          });
          group4.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch4.set(ref, obj);
          });
          group5.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch5.set(ref, obj);
          });
          group6.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch6.set(ref, obj);
          });
        } else if (list.length < 3500) {
          group1.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch1.set(ref, obj);
          });
          group2.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch2.set(ref, obj);
          });
          group3.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch3.set(ref, obj);
          });
          group4.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch4.set(ref, obj);
          });
          group5.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch5.set(ref, obj);
          });
          group6.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch6.set(ref, obj);
          });
          group7.forEach((obj) => {
            const ref = obj.ref;
            delete obj.ref;
            batch7.set(ref, obj);
          });
        }
        console.log(
          group1.length,
          group2.length,
          group3.length,
          group4.length,
          group5.length
        );
        if (group1 && group1.length > 0)
          batch1.commit().then(() => {
            console.log("batch1 done");
          });
        if (group2 && group2.length > 0)
          batch2.commit().then(() => {
            console.log("batch2 done");
          });
        if (group3 && group3.length > 0)
          batch3.commit().then(() => {
            console.log("batch3 done");
          });
        if (group4 && group4.length > 0)
          batch4.commit().then(() => {
            console.log("batch4 done");
          });
        if (group5 && group5.length > 0)
          batch5.commit().then(() => {
            console.log("batch5 done");
          });
        if (group6 && group5.length > 0)
          batch6.commit().then(() => {
            console.log("batch6 done");
          });
        if (group7 && group5.length > 0)
          batch7.commit().then(() => {
            console.log("batch7 done");
          });
      }
    },
    copyTeacher2() {
      var counter = 0;
      if (this.selectedAsuulga) {
        this.selectedAsuulga.availableClassGroups.forEach((classGroup) => {
          this.asuulga.ref
            .collection("teachers")
            .doc(classGroup.id)
            .set({ departmentIndex: classGroup.departmentIndex })
            .then(() => {
              classGroup.teachers.forEach((teacher) => {
                this.asuulga.ref
                  .collection("teachers")
                  .doc(classGroup.id)
                  .collection("teachers")
                  .doc(teacher.id)
                  .set({
                    createdAt: new Date(),
                    numberOfparticipants: teacher.numberOfparticipants,
                  })
                  .then(() => {
                    teacher.lessons.forEach((lesson) => {
                      this.asuulga.ref
                        .collection("teachers")
                        .doc(classGroup.id)
                        .collection("teachers")
                        .doc(teacher.id)
                        .collection("lessons")
                        .doc(lesson.id)
                        .set({
                          createdAt: new Date(),
                          lessonName: lesson.lessonName,
                          numberOfparticipants: lesson.numberOfparticipants,
                        })
                        .then(() => {
                          console.log(++counter);
                        });
                    });
                  });
              });
            });
        });
      }
    },
    _findClassGroups(fullName) {
      var counter = 1;
      this.departments.forEach((department) => {
        for (var i = 0; i < department.classGroups.length; i++) {
          console.log(counter, department.classGroups[i].fullName);
          if (fullName == department.classGroups[i].fullName) counter++;
        }
      });
    },
    _saveBulk() {
      console.log(this.bulkText);
      this.showAddTeacherDialog = false;
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach(async (value) => {
            var lineArray = value.split("\t");
            var newItem = {};
            newItem["teacherName"] = lineArray[0].trim();
            newItem["lessonName"] = lineArray[1].trim();
            newItem["classGroupFullName"] = lineArray[2].trim();
            newItem["numberOfparticipants"] = lineArray[3].trim();
            await this.asuulga.ref
              .collection("teachers")
              .doc(newItem["classGroupFullName"])
              .set(
                {
                  departmentIndex: this.selectedDepartment,
                },
                { merge: true }
              )
              .then(async () => {
                this.asuulga.ref
                  .collection("teachers")
                  .doc(newItem["classGroupFullName"])
                  .collection("teachers")
                  .doc(newItem["teacherName"])
                  .set(
                    {
                      createdAt: new Date(),
                      numberOfparticipants: fb.firestore.FieldValue.increment(
                        Number(newItem["numberOfparticipants"])
                      ),
                    },
                    { merge: true }
                  )
                  .then(() => {
                    console.log("saved teacher... ", newItem["teacherName"]);
                    var xx = {
                      lessonName: newItem["lessonName"],
                      numberOfparticipants: Number(
                        newItem["numberOfparticipants"]
                      ),
                      createdAt: new Date(),
                    };
                    this.asuulga.ref
                      .collection("teachers")
                      .doc(newItem["classGroupFullName"])
                      .collection("teachers")
                      .doc(newItem["teacherName"])
                      .collection("lessons")
                      .doc(newItem["lessonName"])
                      .set(xx)
                      .then(() => {
                        console.log(
                          "saved teacher lessons ... ",
                          newItem["teacherName"],
                          newItem["lessonName"]
                        );
                      });
                  });
              });
          });
        }
      }
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.asuulga-table td {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  cursor: pointer;
}
.bordercell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #f5f591 !important;
  cursor: pointer;
}
.asuulga-table {
  border: 1px solid #e0e0e0;
}

.xcontainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.xresponsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.asuulgateacher table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.asuulgateacher td,
.asuulgateacher th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.asuulgateacher tr:nth-child(even) {
  /* background-color: #dddddd; */
}
</style>
